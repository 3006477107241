/**
 * Copyright (C) Siemens AG 2022. All Rights Reserved. Confidential

 * About File description :
 *
 * This file UtilsService service to handle global data.
 *
 *
*/

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SiTableDirtyEvent } from '@simpl/siemens-brand-ng/table';
import {
    TurnDown,
    SILTurnDown,
    diaphragmSealVersionsFor7MF084,
} from '../common/global-constants';
import { DataForCalculation } from './calculation-data';
import {
    LoadInternalConfigurationData,
    LoadSavedData,
    LoadSavedFrontendData,
} from 'src/app/services/load-data';
import { FormDataForUI, InternalConfigurationData } from './save-data';
import { ConfigurationComponent } from '../main/configuration/configuration.component';
import { ProcessParametersComponent } from '../main/process-parameters/process-parameters.component';
import { Subject } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    version: string = 'V2.1.0.0_66.1.0.1';
    public measuringSpanCounter = 0;
    public extensionLengthCounter = 0;
    public lowSide = false;
    public capillaryValue = false;
    public nextButtonClick = false;
    ambientTempCounter: number;
    public ambientMin;
    public ambientMax;
    public processMin;
    public processMax;
    public pMin;
    public pMax;
    public maxSpan;
    public pressureUnit;
    public staticPressureValueChanges = false;
    staticPressureValue = null;
    unitOfPressure: string;
    public savedConfiguration;
    public onlanguageToggle = 'default';
    formValueChangedAfterLoad: boolean;
    public acceptedTerms = false;
    pressureUnitfactor: number;
    staticPressureValueForValidation: number;
    mountingPositionValueChanged = false;
    mountingPositionValueChangedForHighSide = false;
    mountingPositionValueChangedForLowSide = false;
    public lowSideWithRemoteSeal = true;
    public transmitterMlfb: string = null;
    public remotesealHighSideMlfb: string = null;
    public remotesealLowSideMlfb: string = null;
    public fillingFluidBackButtonClick = false;
    public beyond50 = 0.05;
    TURN_DOWN = TurnDown;
    SIL_Turn_Down = SILTurnDown;
    isSILDevice = false;
    silOption;
    highSideNominalPressureOption = null;
    lowSideNominalPressureOption = null;
    selectedCalculationValue = 'my';
    isRemoteHighSideSelected = true;
    dataForCalculation: DataForCalculation;
    loadInternalConfigurationData: LoadInternalConfigurationData;
    loadSavedData: LoadSavedData;
    loadSavedFrontendData: LoadSavedFrontendData;
    configurationData: LoadSavedData;
    frontendData: LoadSavedFrontendData;
    formData: FormDataForUI;
    internalConfigurationData: InternalConfigurationData;
    isPdfDownloaded = false;
    isLoadedDateChanged = false;
    setupPageData: ConfigurationComponent;
    processParametersPage: ProcessParametersComponent;
    ScrewedDesignNumber=73;
    clearFiltersuject$ = new Subject<boolean>();
    constructor(private readonly titleService: Title) {
        this.resetAllConfigurationData();
    }

    resetAllConfigurationData() {
        this.dataForCalculation = new DataForCalculation();
        this.loadInternalConfigurationData =
            new LoadInternalConfigurationData();
        this.loadSavedData = new LoadSavedData();
        this.loadSavedFrontendData = new LoadSavedFrontendData();
        this.configurationData = new LoadSavedData();
        this.frontendData = new LoadSavedFrontendData();
        this.formData = new FormDataForUI();
        this.internalConfigurationData = new InternalConfigurationData();
        this.internalConfigurationData.measuringpointObject.version =
            this.version;
        this.setupPageData = null;
        this.processParametersPage = null;
    }

    /**
     * Set the page title
     * @param newTitle : the title to be set based on the page load
     */
    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }

    getLanguage() {
        return sessionStorage.getItem('user_lang');
    }

    isEuropeLanguage(): boolean {
        const language = this.getLanguage();
        if (language && !(language == 'en' || language == 'zh')) {
            return true;
        } else 
        {return false;}
    }

    stringToNumber(value: string) {
        if (value != null) { value = value.toString();}
        else { return 0;}
        const language = this.getLanguage();
        if (language && !(language == 'en' || language == 'zh')) {
            const dec = new RegExp('\\' + ',', 'g');
            //  const group = new RegExp('\\' + '.', 'g');
            return Number(value.replace(dec, '.'));
        } else {
            return Number(value);
        }
    }

    numberToDisplayString(value: number): string {
        if (value != null) {
            const language = this.getLanguage();
            if (language && !(language == 'en' || language == 'zh')) {
                return value.toString().replace('.', ',');
            } else {return value.toString();}
        } else {return null;}
    }

    stringToLocalString(value: string): string {
        const language = this.getLanguage();
        if (language && !(language == 'en' || language == 'zh')) {
            return value.replace('.', ',');
        } else {return value.toString();}
    }

    displayNumberValue(textValue: string, newvalue: number) {
        let displayValue = '';
        if (textValue != null) {textValue = textValue.toString();}
        const language = this.getLanguage();
        if (language && !(language == 'en' || language == 'zh')) {
            if (
                displayValue.split('.')[0] === '' ||
                displayValue.split(',')[0] === ''
            ) {
                displayValue = textValue.replace('.', ',');
            } else if (!newvalue.toString().includes('.')) {
                displayValue = textValue.replace('.', ',');
            } else {
                displayValue = newvalue.toString().replace('.', ',');
            }
        } else {
            if (displayValue.split('.')[0] === '') {
                displayValue = textValue;
            } else if (!newvalue.toString().includes('.')) {
                displayValue = textValue;
            } else {
                displayValue = newvalue.toString();
            }
        }
        return displayValue;
    }
    updateCommaOnLoadConfigurationData(value) {
        const language = this.getLanguage();
        if (value && language && !(language == 'en' || language == 'zh')) {
            if (
                value.transmitterInformation &&
                value.transmitterInformation.maximum_measuring_span
            ) {
                value.transmitterInformation.maximum_measuring_span =
                    value.transmitterInformation.maximum_measuring_span.replace(
                        '.',
                        ','
                    );
            }
            if (
                value.remoteSealHighSide &&
                value.remoteSealHighSide.extension_length
            ) {
                value.remoteSealHighSide.extension_length =
                    value.remoteSealHighSide.extension_length.replace('.', ',');
            }
            if (
                value.mountingHighSide &&
                value.mountingHighSide.capillary_length
            ) {
                value.mountingHighSide.capillary_length =
                    value.mountingHighSide.capillary_length.replace('.', ',');
            }
            if (
                value.mountingHighSide &&
                value.mountingHighSide.inner_diameter_capillary
            ) {
                value.mountingHighSide.inner_diameter_capillary =
                    value.mountingHighSide.inner_diameter_capillary.replace(
                        '.',
                        ','
                    );
            }
            if (
                value.mountingHighSide &&
                value.mountingHighSide.height_difference != null
            ) {
                value.mountingHighSide.height_difference =
                    value.mountingHighSide.height_difference
                        .toString()
                        .replace('.', ',');
            }
            // lowside

            if (
                value.remoteSealLowSide &&
                value.remoteSealLowSide.extension_length
            ) {
                value.remoteSealLowSide.extension_length =
                    value.remoteSealLowSide.extension_length.replace('.', ',');
            }
            if (
                value.mountingLowSide &&
                value.mountingLowSide.capillary_length
            ) {
                value.mountingLowSide.capillary_length =
                    value.mountingLowSide.capillary_length.replace('.', ',');
            }
            if (
                value.mountingLowSide &&
                value.mountingLowSide.inner_diameter_capillary
            ) {
                value.mountingLowSide.inner_diameter_capillary =
                    value.mountingLowSide.inner_diameter_capillary.replace(
                        '.',
                        ','
                    );
            }
            if (
                value.mountingLowSide &&
                value.mountingLowSide.height_difference != null
            ) {
                value.mountingLowSide.height_difference =
                    value.mountingLowSide.height_difference
                        .toString()
                        .replace('.', ',');
            }
        }
        return value;
    }

    getHttpParamByDirtyEvent(dirtyEvent: SiTableDirtyEvent): HttpParams {
        let httpParams = new HttpParams().set(
            'pageNumber',
            dirtyEvent.currRow + 1
        );
        httpParams = httpParams.append('pageSize', 15);
        for (const [key, value] of Object.entries(dirtyEvent)) {
            if (
                !(
                    key == 'currRow' ||
                    key == 'maxRow' ||
                    key == 'sortColumn' ||
                    key == 'sortDir'
                )
            ) {
                httpParams = httpParams.append('filters', `${key}=${value}`);
            }
        }
        if (dirtyEvent.sortColumn && dirtyEvent.sortDir) {
            httpParams = httpParams.append(
                'orderBy',
                `${dirtyEvent.sortColumn} ${dirtyEvent.sortDir.toUpperCase()}`
            );
        }
        return httpParams;
    }

    removeNullFromString() {
        if (
            this.transmitterMlfb != null &&
            (this.transmitterMlfb.trim() === '' ||
                this.transmitterMlfb.trim() === 'null')
        ) {
            this.transmitterMlfb = '';
        }
        if (
            this.remotesealLowSideMlfb != null &&
            (this.remotesealLowSideMlfb.trim() === '' ||
                this.remotesealLowSideMlfb.trim() === 'null')
        ) {
            this.remotesealLowSideMlfb = '';
        }
        if (
            this.remotesealHighSideMlfb != null &&
            (this.remotesealHighSideMlfb.trim() === '' ||
                this.remotesealHighSideMlfb.trim() === 'null')
        ) {
            this.remotesealHighSideMlfb = '';
        }
    }

    convert7MF084DataFromLoadData(configurationObject) {
        if (configurationObject) {
            if (
                configurationObject.plusSide.diaphragmSeal &&
                configurationObject.plusSide.diaphragmSeal.id &&
                Number(configurationObject.plusSide.diaphragmSeal.id) === this.ScrewedDesignNumber
            ) {
                const newVersion = diaphragmSealVersionsFor7MF084.find(
                    (item) =>
                        item.code ===
                        configurationObject.plusSide.diaphragmSealVersion.code
                );
                configurationObject.plusSide.diaphragmSealVersion.category =
                    newVersion.category;
                configurationObject.plusSide.diaphragmSealVersion.description =
                    newVersion.description;
            }
            if (
                configurationObject.minusSide !== null &&
                configurationObject.minusSide.diaphragmSeal.id &&
                Number(configurationObject.minusSide.diaphragmSeal.id) === this.ScrewedDesignNumber
            ) {
                const newVersion = diaphragmSealVersionsFor7MF084.find(
                    (item) =>
                        item.code ===
                        configurationObject.minusSide.diaphragmSealVersion.code
                );
                configurationObject.minusSide.diaphragmSealVersion.category =
                    newVersion.category;
                configurationObject.minusSide.diaphragmSealVersion.description =
                    newVersion.description;
            }
        }
        return configurationObject;
    }

    removeLocalStorageData() {
        localStorage.removeItem('configurationFormData');
        localStorage.removeItem('processParametersFormData');
        localStorage.removeItem('dataForCalculation');
        localStorage.removeItem('measuringSpanCounter');
        localStorage.removeItem('beyond50');
        localStorage.removeItem('frontendData');
        localStorage.removeItem('configurationData');
        localStorage.removeItem('internalConfigurationData');
        localStorage.removeItem('loadInternalConfigurationData');
        localStorage.removeItem('lowSideMountingId');
        localStorage.removeItem('MountingID');
        localStorage.removeItem('FillingFluid');
        localStorage.removeItem('FillingFluidCode');
        localStorage.removeItem('diaphragmId');
        localStorage.removeItem('diaphragmIdLowSide');
        localStorage.removeItem('nominalSizeCode');
        localStorage.removeItem('versionId');
        localStorage.removeItem('nominalLowSizeCode');
        localStorage.removeItem('versionIdLowSide');
        localStorage.removeItem('FoilsId');
        localStorage.removeItem('DiaphragmFunctionUnitId');
        localStorage.removeItem('LowFoilsId');
        localStorage.removeItem('LowDiaphragmFunctionUnitId');
        localStorage.removeItem('TransmitterId');
        localStorage.removeItem('InstrumentType');
        localStorage.removeItem('InstrumentVersionId');
        localStorage.removeItem('negative_id');
        localStorage.removeItem('isLoadedDateChanged');
    }

    clearAllFilterData(value:boolean)
    {
        this.clearFiltersuject$.next(value);
    }
}
